import * as dateFns from 'date-fns'

export function isTodaysDate(date: Date) {
    let workingDate = dateFns.getTime(date)

    let startOfDay = dateFns.getTime(dateFns.startOfDay(new Date()))
    let endOfDay = dateFns.getTime(dateFns.endOfDay(new Date()))

    return workingDate >= startOfDay && workingDate <= endOfDay
}

export function getTodayUTC() {
    return new Date(new Date().setHours(0, 0, 0, 0))
}

export function getAddDaysUTC(days: number) {
    let todayUTC = getTodayUTC()
    return dateFns.addDays(todayUTC, days)
}

export function getAddMonthsUTC(months: number) {
    let todayUTC = getTodayUTC()
    return dateFns.addMonths(todayUTC, months)
}

export function getDateFromUct(utcDate: string) {
    return dateFns.parseISO(utcDate + 'Z')
}

export function getEndOfDayTime(date: Date) {
    return dateFns.addSeconds(date, -1)
}

export function getTimeTextFromUct(serverDate: Maybe<string> | undefined): string {
    if (serverDate) return dateFns.format(getDateFromUct(serverDate), 'h:mma')
    else return ''
}

export function getDateTextFromUct(serverDate: Maybe<string> | undefined): string {
    if (serverDate) return dateFns.format(getDateFromUct(serverDate), 'EEEE, dd MMMM yyyy')
    else return ''
}
